/* AboutComponent.css */

.about-content {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 800px; /* Set a maximum width */
    margin: 0 auto; /* Center the content */
}

.about-content h1 {
    font-size: 24px; /* Decreased size of h1 */
    font-weight: bold;
    margin-bottom: 15px;
}

.about-content h2 {
    font-size: 20px; /* Decreased size of h2 */
    font-weight: bold;
    margin-bottom: 15px;
}

.about-content p {
    margin-bottom: 15px;
}

.about-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
}

.about-content li {
    margin-bottom: 10px;
}

.about-content a {
    color: #007bff;
    text-decoration: none;
}

.about-content a:hover {
    text-decoration: underline;
}

.about-content b {
    color: #000;
}
